export const OccupationOptions = [
  "Student",
  "Medical Worker",
  "Working Abroad",
  "Govt Employee",
  "Private Employee",
  "Home maker/Housewife",
  "Others",
];

export const HealthCentreTypeOptions = [
  "Family Health Centres",
  "Educational Inst",
  "Private Hospital",
  "Other",
  "Hostel",
  "Hotel",
  "Lodge",
  "TeleMedicine",
  "Govt Hospital",
  "Primary Health Centres",
  "24x7 Public Health Centres",
  "Family Health Centres",
  "Community Health Centres",
  "Urban Primary Health Center",
  "Taluk Hospitals",
  "Taluk Headquarters Hospitals",
  "Women and Child Health Centres",
  "General hospitals",
  "District Hospitals",
];
